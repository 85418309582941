import { formatDateForNotificationsAPI } from '@energybox/react-ui-library/dist/utils';
import { Service } from '../config';

export enum Actions {
  COOLER_ENERGY_RECOMMENDATIONS_LOADING = '@@analytics/COOLER_ENERGY_RECOMMENDATIONS_LOADING',
  COOLER_ENERGY_RECOMMENDATIONS_ERROR = '@@analytics/COOLER_ENERGY_RECOMMENDATIONS_ERROR',
  COOLER_ENERGY_RECOMMENDATIONS_SUCCESS = '@@analytics/COOLER_ENERGY_RECOMMENDATIONS_SUCCESS',

  UNNECESSARY_DOOR_ACCESS_LOADING = '@@analytics/UNNECESSARY_DOOR_ACCESS_LOADING',
  UNNECESSARY_DOOR_ACCESS_ERROR = '@@analytics/UNNECESSARY_DOOR_ACCESS_ERROR',
  UNNECESSARY_DOOR_ACCESS_SUCCESS = '@@analytics/UNNECESSARY_DOOR_ACCESS_SUCCESS',

  TEMPERATURE_COMFORT_RECOMMENDATIONS_LOADING = '@@analytics/TEMPERATURE_COMFORT_RECOMMENDATIONS_LOADING',
  TEMPERATURE_COMFORT_RECOMMENDATIONS_ERROR = '@@analytics/TEMPERATURE_COMFORT_RECOMMENDATIONS_ERROR',
  TEMPERATURE_COMFORT_RECOMMENDATIONS_SUCCESS = '@@analytics/TEMPERATURE_COMFORT_RECOMMENDATIONS_SUCCESS',

  AMBIENT_DOT_LOADING = '@@analytics/AMBIENT_DOT_LOADING',
  AMBIENT_DOT_SUCCESS = '@@analytics/AMBIENT_DOT_SUCCESS',
  AMBIENT_DOT_ERROR = '@@analytics/AMBIENT_DOT_ERROR',

  SITE_TEMP_CHECK_LOADING = '@@analytics/SITE_TEMP_CHECK_LOADING',
  SITE_TEMP_CHECK_SUCCESS = '@@analytics/SITE_TEMP_CHECK_SUCCESS',
  SITE_TEMP_CHECK_ERROR = '@@analytics/SITE_TEMP_CHECK_ERROR',

  START_ORGANIZATION_CO2_REPORT_SUCCESS = '@@analytics/START_ORGANIZATION_CO2_REPORT_SUCCESS',
  START_ORGANIZATION_CO2_REPORT_LOADING = '@@analytics/START_ORGANIZATION_CO2_REPORT_LOADING',
  START_ORGANIZATION_CO2_REPORT_ERROR = '@@analytics/START_ORGANIZATION_CO2_REPORT_ERROR',

  GET_ORGANIZATION_CO2_REPORT_SUCCESS = '@@analytics/GET_ORGANIZATION_CO2_REPORT_SUCCESS',
  GET_ORGANIZATION_CO2_REPORT_LOADING = '@@analytics/GET_ORGANIZATION_CO2_REPORT_LOADING',
  GET_ORGANIZATION_CO2_REPORT_ERROR = '@@analytics/GET_ORGANIZATION_CO2_REPORT_ERROR',

  START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS = '@@analytics/START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS',
  START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING = '@@analytics/START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING',
  START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR = '@@analytics/START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR',

  GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS = '@@analytics/GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS',
  GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING = '@@analytics/GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING',
  GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR = '@@analytics/GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR',

  START_ORGANIZATION_ENERGY_PDF_SUCCESS = '@@analytics/START_ORGANIZATION_ENERGY_PDF_SUCCESS',
  START_ORGANIZATION_ENERGY_PDF_LOADING = '@@analytics/START_ORGANIZATION_ENERGY_PDF_LOADING',
  START_ORGANIZATION_ENERGY_PDF_ERROR = '@@analytics/START_ORGANIZATION_ENERGY_PDF_ERROR',

  GET_ORGANIZATION_ENERGY_PDF_SUCCESS = '@@analytics/GET_ORGANIZATION_ENERGY_PDF_SUCCESS',
  GET_ORGANIZATION_ENERGY_PDF_LOADING = '@@analytics/GET_ORGANIZATION_ENERGY_PDF_LOADING',
  GET_ORGANIZATION_ENERGY_PDF_ERROR = '@@analytics/GET_ORGANIZATION_ENERGY_PDF_ERROR',

  START_ORG_NOH_SUCCESS = '@@analytics/START_ORG_NOH_SUCCESS',
  START_ORG_NOH_LOADING = '@@analytics/START_ORG_NOH_LOADING',
  START_ORG_NOH_ERROR = '@@analytics/START_ORG_NOH_ERROR',

  GET_ORG_NOH_SUCCESS = '@@analytics/GET_ORG_NOH_SUCCESS',
  GET_ORG_NOH_LOADING = '@@analytics/GET_ORG_NOH_LOADING',
  GET_ORG_NOH_ERROR = '@@analytics/GET_ORG_NOH_ERROR',

  START_MONTHLY_NOH_SUCCESS = '@@analytics/START_MONTHLY_NOH_SUCCESS',
  START_MONTHLY_NOH_LOADING = '@@analytics/START_MONTHLY_NOH_LOADING',
  START_MONTHLY_NOH_ERROR = '@@analytics/START_MONTHLY_NOH_ERROR',

  GET_MONTHLY_NOH_SUCCESS = '@@analytics/GET_MONTHLY_NOH_SUCCESS',
  GET_MONTHLY_NOH_LOADING = '@@analytics/GET_MONTHLY_NOH_LOADING',
  GET_MONTHLY_NOH_ERROR = '@@analytics/GET_MONTHLY_NOH_ERROR',
}

export const getCoolerEnergyRecommendations = (
  orgId: number | string,
  fromDate: string,
  toDate: string,
  siteId: number | string
) => ({
  //fromDate + toDate is 'yyyy-MM-dd'
  service: Service.analytics,
  type: 'API_GET',
  path: `/report/cooler_energy_recommendations/${orgId}/${fromDate}/${toDate}`,
  loading: {
    type: Actions.COOLER_ENERGY_RECOMMENDATIONS_LOADING,
    siteId,
  },
  success: {
    type: Actions.COOLER_ENERGY_RECOMMENDATIONS_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.COOLER_ENERGY_RECOMMENDATIONS_ERROR,
    siteId,
  },
});

export const getUnnecessaryDoorAccess = (
  orgId: number | string,
  fromDate: string,
  toDate: string,
  siteId: number | string
) => ({
  //fromDate + toDate is 'yyyy-MM-dd'
  service: Service.analytics,
  type: 'API_GET',
  path: `/report/unnecessary_door_access/${orgId}/${fromDate}/${toDate}`,
  loading: {
    type: Actions.UNNECESSARY_DOOR_ACCESS_LOADING,
    siteId,
  },
  success: {
    type: Actions.UNNECESSARY_DOOR_ACCESS_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.UNNECESSARY_DOOR_ACCESS_ERROR,
    siteId,
  },
});

export const getACEnergyRecommendations = (
  orgId: number | string,
  fromDate: string,
  toDate: string,
  siteId: number | string
) => ({
  //fromDate + toDate is 'yyyy-MM-dd'
  service: Service.analytics,
  type: 'API_GET',
  path: `/report/ac_energy_recommendations/${orgId}/${fromDate}/${toDate}`,
  loading: {
    type: Actions.TEMPERATURE_COMFORT_RECOMMENDATIONS_LOADING,
    siteId,
  },
  success: {
    type: Actions.TEMPERATURE_COMFORT_RECOMMENDATIONS_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.TEMPERATURE_COMFORT_RECOMMENDATIONS_ERROR,
    siteId,
  },
});

export const getAmbientDotReadings = (
  siteId: number,
  fromDate: string,
  toDate: string
) => ({
  //fromDate + toDate is 'yyyy-MM-dd'
  service: Service.analytics,
  type: 'API_GET',
  path: `/report/ambient_dot_report/${siteId}/${fromDate}/${toDate}`,
  loading: {
    type: Actions.AMBIENT_DOT_LOADING,
    siteId,
  },
  success: {
    type: Actions.AMBIENT_DOT_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.AMBIENT_DOT_ERROR,
    siteId,
  },
});

export const getSiteTempCheck = (
  siteId: number,
  fromDate: string,
  toDate: string
) => ({
  //fromDate + toDate is 'yyyy-MM-dd'
  service: Service.analytics,
  type: 'API_GET',
  path: `/report/temperature_check/${siteId}/${fromDate}/${toDate}`,
  loading: {
    type: Actions.SITE_TEMP_CHECK_LOADING,
  },
  success: {
    type: Actions.SITE_TEMP_CHECK_SUCCESS,
  },
  error: {
    type: Actions.SITE_TEMP_CHECK_ERROR,
  },
});

export const startOrganizationCO2Report = (
  organizationId: number | string,
  reportDate: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_co2_report/${organizationId}/${reportDate}`,
  loading: {
    type: Actions.START_ORGANIZATION_CO2_REPORT_LOADING,
    organizationId,
    reportDate,
  },
  success: {
    type: Actions.START_ORGANIZATION_CO2_REPORT_SUCCESS,
    organizationId,
    reportDate,
  },
  error: {
    type: Actions.START_ORGANIZATION_CO2_REPORT_ERROR,
    organizationId,
    reportDate,
  },
});

export const getOrganizationCO2Report = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_co2_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORGANIZATION_CO2_REPORT_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORGANIZATION_CO2_REPORT_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORGANIZATION_CO2_REPORT_ERROR,
    organizationId,
    timestamp,
  },
});

export const startOrganizationEnergySummary = (
  organizationId: number | string,
  reportDate: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_energy_summary_report/${organizationId}/${reportDate}`,
  loading: {
    type: Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING,
    organizationId,
    reportDate,
  },
  success: {
    type: Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS,
    organizationId,
    reportDate,
  },
  error: {
    type: Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR,
    organizationId,
    reportDate,
  },
});

export const getOrganizationEnergySummary = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_energy_summary_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR,
    organizationId,
    timestamp,
  },
});

export const startOrganizationEnergyPdf = (
  organizationId: number | string,
  reportDate: string,
  target: number | string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_energy_pdf/${organizationId}/${reportDate}?target=${target}`,
  loading: {
    type: Actions.START_ORGANIZATION_ENERGY_PDF_LOADING,
    organizationId,
    reportDate,
    target,
  },
  success: {
    type: Actions.START_ORGANIZATION_ENERGY_PDF_SUCCESS,
    organizationId,
    reportDate,
    target,
  },
  error: {
    type: Actions.START_ORGANIZATION_ENERGY_PDF_ERROR,
    organizationId,
    reportDate,
    target,
  },
});

export const getOrganizationEnergyPdf = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_energy_pdf/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORGANIZATION_ENERGY_PDF_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORGANIZATION_ENERGY_PDF_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORGANIZATION_ENERGY_PDF_ERROR,
    organizationId,
    timestamp,
  },
});

export const startOrgNOHReport = (
  organizationId: number | string,
  startDate: Date,
  endDate: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_noh_report/${organizationId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(endDate)}`,
  loading: {
    type: Actions.START_ORG_NOH_LOADING,
    organizationId,
    startDate,
    endDate,
  },
  success: {
    type: Actions.START_ORG_NOH_SUCCESS,
    organizationId,
    startDate,
    endDate,
  },
  error: {
    type: Actions.START_ORG_NOH_ERROR,
    organizationId,
    startDate,
    endDate,
  },
});

export const getOrgNOHReport = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_noh_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORG_NOH_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORG_NOH_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORG_NOH_ERROR,
    organizationId,
    timestamp,
  },
});

export const startMonthlyNOHReport = (
  organizationId: number | string,
  startDate: Date,
  endDate: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/monthly_noh_report/${organizationId}/${formatDateForNotificationsAPI(
    startDate
  )}`,
  loading: {
    type: Actions.START_MONTHLY_NOH_LOADING,
    organizationId,
    startDate,
    endDate,
  },
  success: {
    type: Actions.START_MONTHLY_NOH_SUCCESS,
    organizationId,
    startDate,
    endDate,
  },
  error: {
    type: Actions.START_MONTHLY_NOH_ERROR,
    organizationId,
    startDate,
    endDate,
  },
});

export const getMonthlyNOHReport = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/monthly_noh_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_MONTHLY_NOH_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_MONTHLY_NOH_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_MONTHLY_NOH_ERROR,
    organizationId,
    timestamp,
  },
});
