import { Equipment, Site } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnalyticsEnergyConsumptionPreviousPeriod as getSiteConsumptionPrevTimePeriod,
  getSiteConsumptionLastMonth,
  getSiteConsumptionSelectedTimePeriod,
  getSiteConsumptionThreeMonthsAgo,
  getSiteConsumptionTwoMonthsAgo,
} from '../../../actions/energy';
import { getEquipmentBySiteId } from '../../../actions/equipment';
import { getSite } from '../../../actions/sites';
import { getSpacesBySiteId } from '../../../actions/spaces';
import { useCurrentOrganizationId } from '../../../hooks/users';
import { useReportTimeFormats } from '../../../hooks/utils';
import { ApplicationState } from '../../../reducers';
import EnergyReportPage from '../EnergyReportPage';

type Props = {
  siteId: string;
  fromDate: string;
  toDate: string;
};

const EnergyReportPageContainer: React.FC<Props> = ({
  fromDate,
  toDate,
  siteId,
}) => {
  const dispatch = useDispatch();
  const siteIdNumber = parseInt(siteId);
  const orgId = useCurrentOrganizationId();

  const site = useSelector<ApplicationState, Site | undefined>(({ sites }) => {
    return sites.sitesById[siteId];
  });

  const reportTimeFormats = useReportTimeFormats(
    fromDate,
    toDate,
    site?.timeZone
  );
  const {
    fromDateInBrowserTimezone,
    toDateInBrowserTimezone,
    prevFromDateString,
    prevToDateString,
    reportDifferenceInDays,
  } = reportTimeFormats;

  const equipments = useSelector<ApplicationState, Equipment[] | undefined>(
    ({ equipment }) => {
      return equipment.equipmentBySiteId[siteId];
    }
  );

  useEffect(() => {
    dispatch(getSite(siteIdNumber));
    dispatch(getEquipmentBySiteId(siteIdNumber));
    dispatch(getSpacesBySiteId(siteIdNumber));
  }, [siteIdNumber]);

  useEffect(() => {
    dispatch(
      getSiteConsumptionSelectedTimePeriod(
        fromDateInBrowserTimezone,
        toDateInBrowserTimezone,
        siteIdNumber
      )
    );

    dispatch(
      getSiteConsumptionLastMonth(toDateInBrowserTimezone, siteIdNumber)
    );
    dispatch(
      getSiteConsumptionTwoMonthsAgo(toDateInBrowserTimezone, siteIdNumber)
    );
    dispatch(
      getSiteConsumptionThreeMonthsAgo(toDateInBrowserTimezone, siteIdNumber)
    );
  }, [fromDateInBrowserTimezone, toDateInBrowserTimezone, siteIdNumber]);

  useEffect(() => {
    if (isDefined(prevFromDateString) && isDefined(prevToDateString)) {
      dispatch(
        getSiteConsumptionPrevTimePeriod(
          prevFromDateString,
          prevToDateString,
          siteIdNumber
        )
      );
    }
  }, [prevFromDateString, prevToDateString, siteIdNumber]);

  if (!site || !orgId) return <div />;

  return (
    <EnergyReportPage
      site={site}
      equipments={equipments}
      fromDate={fromDateInBrowserTimezone}
      toDate={toDateInBrowserTimezone}
      reportNumberOfDays={reportDifferenceInDays}
    />
  );
};

export default EnergyReportPageContainer;
