import React, { ReactElement, useEffect, useState } from 'react';
import ReactPDF, {
  PDFViewer,
  Document,
  Page,
  View,
  pdf,
} from '@react-pdf/renderer';

import { PdfHeaderProps, PdfViewHeader } from './PdfViewHeader';
import { PdfViewFooter } from './PdfViewFooter';

type Props = {
  headerProps: PdfHeaderProps;
  content: ReactElement<ReactPDF.ViewProps>;
  debug?: boolean;
};

const PdfViewContainer: React.FC<Props> = ({ headerProps, content, debug }) => {
  const [res, setRes] = useState<JSX.Element>(<div>laoding</div>);
  const doc = (
    <Document>
      <Page
        style={{
          padding: '20 40',
          fontSize: '10pt',
          fontFamily: 'Open Sans',
        }}
      >
        <PdfViewHeader {...headerProps} />
        {content}
        <View style={{ flexGrow: 1 }} />
        {/* To push the last footer to the page end */}
        <PdfViewFooter />
      </Page>
    </Document>
  );

  useEffect(() => {
    if (debug)
      setRes(
        <div style={{ height: '100vh', overflow: 'hidden' }}>
          <PDFViewer width="100%" height="100%">
            {doc}
          </PDFViewer>
        </div>
      );
    else {
      (async () => {
        const blob = await pdf(doc).toBlob();
        const objUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objUrl;
        a.setAttribute('download', 'result.pdf');
        a.click();
        setRes(<div>done</div>);
      })();
    }
  }, []);

  return res;
};

export default PdfViewContainer;
