import {
  Site,
} from '@energybox/react-ui-library/dist/types';
import { AreaUnitLabels } from '@energybox/react-ui-library/dist/types/Global';
import {
  global,
  isDefined,
  KWH_UNIT,
} from '@energybox/react-ui-library/dist/utils';
import format from 'date-fns/format';

import React from 'react';
import BigNumberWithUnitAndHeading from '../../../components/BigNumberWithUnitAndHeading';
import NumberHeaderBox from '../../../components/NumberHeaderBox';
import VerticalDivider from '../../../components/VerticalDivider';
import { useAreaUnit } from '../../../hooks/useAppDetails';
import { convertMeterSquaredToFeetSquared } from '../../../util';
import { Colors } from '../../../utils/colors';

type Props = {
  site: Site;
  totalConsumption: number | undefined;
  prevTotalConsumption: number | undefined;
  fromDate: Date;
  toDate: Date;
  reportNumberOfDays: number | undefined;
};

const EnergyReportConsumptionInfo = ({
  site,
  totalConsumption,
  prevTotalConsumption,
  fromDate,
  toDate,
  reportNumberOfDays,
}: Props) => {
  const areaUnit = useAreaUnit();

  const displaySelectedTimePeriod = () => {
    return `${format(fromDate, 'MMM dd')} - ${format(toDate, 'MMM dd')}`;
  };

  const determineEnergyPerSiteArea = () => {
    if (!isDefined(site.areaTotal) || !isDefined(totalConsumption)) {
      return null;
    }

    const convertedSiteArea = areaUnit === AreaUnitLabels.M2
      ? site.areaTotal
      : convertMeterSquaredToFeetSquared(site.areaTotal);

    return Number((totalConsumption / convertedSiteArea).toFixed(2));
  };

  const determinePreviousTimePeriodPercentage = () => {
    if (!isDefined(totalConsumption) || !isDefined(prevTotalConsumption)) {
      return null;
    }

    return (totalConsumption / prevTotalConsumption) * 100 - 100;
  };

  return (
    <NumberHeaderBox>
      <BigNumberWithUnitAndHeading
        value={totalConsumption ? Number(totalConsumption.toFixed()) : null}
        headerText={`Energy Consumption (${displaySelectedTimePeriod()})`}
        color={Colors.AMBIENT}
        unit={'kWh'}
      />

      <VerticalDivider />

      <BigNumberWithUnitAndHeading
        unit={KWH_UNIT}
        value={determineEnergyPerSiteArea()}
        headerText={<span>Energy Consumption / {areaUnit}</span>}
        color={Colors.AMBIENT}
      />

      <VerticalDivider />

      <BigNumberWithUnitAndHeading
        isDelta
        unit={'%'}
        value={determinePreviousTimePeriodPercentage()}
        headerText={`Compared to Previous ${reportNumberOfDays ||
          global.NOT_AVAILABLE} Days`}
        color={Colors.AMBIENT}
        decimals={1}
      />
    </NumberHeaderBox>
  );
};

export default EnergyReportConsumptionInfo;
