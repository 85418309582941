import { getDifferenceInDays } from '@energybox/react-ui-library/dist/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

export type ReportTimeFormats = {
  fromDateInBrowserTimezone: Date;
  toDateInBrowserTimezone: Date;
  fromDateISO?: string;
  toDateISO?: string;
  fromDateUnixTimestamp?: number;
  toDateUnixTimestamp?: number;
  prevFromDateUnixTimestamp?: number;
  prevToDateUnixTimestamp?: number;
  reportDifferenceInDays?: number;
  prevFromDateString?: string;
  prevToDateString?: string;
};

/**
 * @param fromDate ISO date format (2021-06-01)
 * @param toDate ISO date format (2021-06-01)
 * @param timezone defaults to local timezone if undefined
 */
export const useReportTimeFormats = (
  fromDate: string,
  toDate: string,
  ianaTimeZoneCode?: string
): ReportTimeFormats => {
  return useMemo(() => {
    const reportDifferenceInDays = getDifferenceInDays(fromDate, toDate);

    const fromDateInBrowserTimezone = DateTime.fromISO(fromDate)
      .toLocal()
      .startOf('day')
      .toJSDate();
    const toDateInBrowserTimezone = DateTime.fromISO(toDate)
      .toLocal()
      .endOf('day')
      .toJSDate();

    if (!ianaTimeZoneCode) {
      return {
        fromDateInBrowserTimezone,
        toDateInBrowserTimezone,
      };
    }

    const fromDateISO = DateTime.fromISO(fromDate, { zone: ianaTimeZoneCode })
      .startOf('day')
      .setZone('utc')
      .toISO();
    const toDateISO = DateTime.fromISO(toDate, { zone: ianaTimeZoneCode })
      .endOf('day')
      .setZone('utc')
      .toISO();

    const fromDateUnixTimestampInSeconds = DateTime.fromISO(
      fromDateISO
    ).toSeconds();
    const toDateUnixTimestampInSeconds = DateTime.fromISO(
      toDateISO
    ).toSeconds();

    const prevToDateUnixTimestamp = fromDateUnixTimestampInSeconds - 1;
    const prevFromDateUnixTimestamp =
      prevToDateUnixTimestamp -
      (toDateUnixTimestampInSeconds - fromDateUnixTimestampInSeconds);

    const prevFromDateString = DateTime.fromSeconds(prevFromDateUnixTimestamp)
      .setZone(ianaTimeZoneCode)
      .toISODate();
    const prevToDateString = DateTime.fromSeconds(prevToDateUnixTimestamp)
      .setZone(ianaTimeZoneCode)
      .toISODate();

    return {
      fromDateInBrowserTimezone,
      toDateInBrowserTimezone,
      fromDateISO,
      toDateISO,
      fromDateUnixTimestamp: fromDateUnixTimestampInSeconds,
      toDateUnixTimestamp: toDateUnixTimestampInSeconds,
      prevFromDateUnixTimestamp,
      prevToDateUnixTimestamp,
      prevFromDateString,
      prevToDateString,
      reportDifferenceInDays,
    };
  }, [fromDate, toDate, ianaTimeZoneCode]);
};
