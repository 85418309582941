import React from 'react';
import {
  InspectionComponentName,
  InspectionDataField,
  InspectionDataFieldsByKey,
  InspectionDetailLevel,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import {
  getTitle,
  getDetailFields,
  getSummaryFields,
} from '@energybox/react-ui-library/dist/utils/inspection';
import { ComponentHeader, InspectionComponent } from './InspectionComponent';
import { DeviceIcon } from '../Pdf/Icons';

type Props = {
  data: InspectionDataFieldsByKey;
  isSuperEdge?: boolean;
};

export const EdgeController: React.FC<Props> = ({ data, isSuperEdge }) => {
  const fields = [
    {
      name: 'Serial Number',
      key: 'serial_number',
    },
    {
      name: 'RSSI',
      key: 'rssi',
      transformValue: value =>
        !isNaN(value) && isDefined(value)
          ? `${value} dB`
          : global.NOT_AVAILABLE,
    },
    {
      name: 'MAC Address (UUID)',
      key: 'UUID',
    },
    {
      name: 'OS Version',
      key: 'ios_version',
    },
    {
      name: 'Gateway IP',
      key: 'northbound_ip',
    },
    {
      name: 'Southbound IP',
      key: 'southbound_ip',
    },
    ...(isSuperEdge
      ? [
          //Super Edge controller array has the following additional keys
          {
            name: '(Balena) Gateway Status',
            key: 'balena_status',
          },
          {
            name: 'Build Version',
            key: 'app_version',
            transformValue: value =>
              isDefined(value) ? value.split('-')[0] : global.NOT_AVAILABLE,
          },
          {
            name: 'Connection Type: 4G/LAN',
            key: 'connection_type',
          },
          {
            name: '4G Carrier/Network',
            key: 'carrier',
          },
          {
            name: '4G APN',
            key: 'apn',
          },
          {
            name: '4G Fallback Setting',
            key: 'fallback',
          },
          {
            name: 'Wifi Enabled',
            key: 'wifi',
          },
        ]
      : [
          //Edge controller array has the following additional keys
          {
            name: 'Local MQTT Host',
            key: 'mqtt_host',
          },
          {
            name: 'Control App Version',
            key: 'app_version',
          },
          {
            name: 'Connectivity',
          },
          {
            name: 'APN Settings',
          },
        ]),
  ];
  const hubAppFields = [
    {
      name: 'MCU Address (UUID)',
      key: 'UUID',
    },
    {
      name: 'Firmware Version',
      key: 'firmware_version',
    },
    {
      name: 'RF Pan ID',
      key: 'rf_pan_id',
    },
    {
      name: 'MQTT Broker',
      key: 'mqtt_broker',
    },
  ];

  const superhubData = Object.entries(data)
    .filter(([key]) => key.startsWith('superhub_'))
    .reduce((acc, [key, value]) => {
      const newKey = key.replace('superhub_', '');
      acc[newKey] = value;
      return acc;
    }, {});

  const device = (data.device as InspectionDataField)?.field;
  const title =
    InspectionComponentName.EDGE_CONTROLLER + (device ? ` (${device})` : '');
  const subtitle = getTitle(data);
  const summaryFields = getSummaryFields(data);

  const props = {
    title,
    titleIcon: <DeviceIcon type={InspectionComponentName.EDGE_CONTROLLER} />,
    subtitle,
    summaryFields,
    detailFields: getDetailFields(fields, data, InspectionDetailLevel.ALL),
    detailTableHeader: !isSuperEdge ? (
      undefined
    ) : (
      <ComponentHeader
        title={'Hub App (Radio Network)'}
        summaryFields={getSummaryFields(superhubData)}
      />
    ),
    detailExtraFields: isSuperEdge
      ? getDetailFields(hubAppFields, superhubData)
      : undefined,
  };

  return <InspectionComponent {...props} />;
};
