import * as Routes from '../../../routes';
import SitesPerformanceChart from '../SitesPerformanceChart/SitesPerformanceChart';
import styles from './SitesPerformanceTile.module.css';

type SitesPerformanceData = {
  [siteId: number]: {
    site_title: string;
    site_eui_existing: number;
    site_eui_est: {
      eui_est: number;
      eui_status: string;
    };
    monthly_eui: {
      [date: string]: number;
    };
  };
};

type Props = {
  className?: string;
  data: SitesPerformanceData;
  isLoading: boolean;
  selectedYear: number | string;
};

const SitesPerformanceTile: React.FC<Props> = ({
  className,
  data,
  isLoading,
  selectedYear,
}) => {
  const processedData: {
    siteId: number | string;
    site_title: string;
    site_eui_existing: number;
    site_eui_est: {
      eui_est: number;
      eui_status: string;
    };
    monthly_eui: {
      [date: string]: number;
    };
  }[] = [];
  for (const [key, value] of Object.entries(data)) {
    processedData.push({
      siteId: key,
      site_title: value.site_title,
      site_eui_existing: value.site_eui_existing,
      site_eui_est: value.site_eui_est,
      monthly_eui: value.monthly_eui,
    });
  }
  return (
    <div className={styles.tileContainer}>
      <div className={styles.tileTitle}>
        {'Least and Most Efficient Site'}
      </div>
      <SitesPerformanceChart
        isLoading={isLoading}
        data={processedData}
        selectedYear={selectedYear}
        height={550}
      />
    </div>
  );
};

export default SitesPerformanceTile;
