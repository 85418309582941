import { Table, Tooltip } from '@energybox/react-ui-library/dist/components';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import styles from './NonOperatingHourEfficiencyTile.module.css';
import * as Routes from '../../../routes';
import {
  classNames,
  KWH_UNIT,
  shortenString,
} from '@energybox/react-ui-library/dist/utils';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import { useOrgNOHReport } from '../../../hooks/useAnalytics';
import { formatNumber, roundToRelativeScale } from '@energybox/react-ui-library/dist/utils/number';
import { useAppLocale } from '../../../hooks/useAppDetails';

type Props = {
  className?: string;
  equipmentGroup?: string;
  data: {
    data: any,
    isLoading: boolean,
    isReportReady: boolean,
  };
  isLoading?: boolean;
};

const colors = ['var(--accent-base)', 'var(--accent-baseMinus75)'];

const columns = [
  {
    header: '',
    cellContent: ({ name }) => (
      <span
        style={{ lineHeight: '2rem', fontWeight: 600 }}
        className={styles.index}
      >
        {name}
      </span>
    ),
  },
  {
    header: 'Operating Hour',
    rightAlignContent: true,
    cellContent: ({ operating }) => (
      <div style={{ lineHeight: '2rem' }}>{typeof operating === "string" ? operating : formatNumber(operating, 1)}</div>
    ),
  },
  {
    header: 'Non-Operating Hour',
    rightAlignContent: true,
    cellContent: ({ nonOperating }) => (
      <div style={{ lineHeight: '2rem' }}>{typeof nonOperating === "string" ? nonOperating : formatNumber(nonOperating, 1)}</div>
    ),
  },
];

const NonOperatingHourEfficiencyTile = ({
  data: orgNOHReportData,
  className,
  equipmentGroup,
}: Props) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const locale = useAppLocale();
  const { data } = orgNOHReportData;
  const bySiteData = data?.by_site;
  const byOrgData = data?.by_org;
  const { org_hourly_eui_in, org_hourly_eui_out } = data || {};
  const { noh_efficiency, op_hour, non_op_hour } = byOrgData || {};

  const header = 'Operating Hour (OH) vs Non-Operating Hour (NOH)';

  const dataA = [
    {
      name: 'Operating Hour',
      energyConsumption: roundToRelativeScale(op_hour?.energy_total || 0),
      noOfHour: roundToRelativeScale(op_hour?.hours_total || 0),
      consumptionPerHour: roundToRelativeScale(op_hour?.energy_per_hour || 0),
      hourlyEUI: roundToRelativeScale(op_hour?.hourly_eui || 0),
    },
    {
      name: 'Non-Operating Hour',
      energyConsumption: roundToRelativeScale(non_op_hour?.energy_total || 0),
      noOfHour: roundToRelativeScale(non_op_hour?.hours_total || 0),
      consumptionPerHour: roundToRelativeScale(
        non_op_hour?.energy_per_hour || 0
      ),
      hourlyEUI: roundToRelativeScale(non_op_hour?.hourly_eui || 0),
    },
  ];

  const pivotData = [
    {
      name: 'Energy Consumption (kWh)',
      operating: roundToRelativeScale(op_hour?.energy_total || 0),
      nonOperating: roundToRelativeScale(non_op_hour?.energy_total || 0),
    },
    {
      name: 'No. of Hours',
      operating: roundToRelativeScale(op_hour?.hours_total || 0),
      nonOperating: roundToRelativeScale(non_op_hour?.hours_total || 0),
    },
    {
      name: 'Avg. per hour',
      operating: roundToRelativeScale(op_hour?.energy_per_hour || 0),
      nonOperating: roundToRelativeScale(non_op_hour?.energy_per_hour || 0),
    },
    {
      name: 'Hourly EUI (Wh/m²)',
      operating: org_hourly_eui_in !== null ? roundToRelativeScale(org_hourly_eui_in * 1000) : '-',
      nonOperating: org_hourly_eui_out !== null ? roundToRelativeScale(org_hourly_eui_out * 1000) : '-',
    },
  ];

  return (
      <>
        <div
          className={`${styles.headerContainer} ${className ? className : ''}`}
        >
          <div className={styles.titleContainer}>
            {header}
          </div>
        </div>
        <div className={styles.nohContainer}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={450}>
              <Pie
                data={dataA}
                dataKey="energyConsumption"
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={120}
                startAngle={90}
                endAngle={450}
                legendType={'circle'}
              >
                {dataA.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
              <Legend
                verticalAlign={'bottom'}
                iconSize={20}
                formatter={renderLegendText}
              />
            </PieChart>
          </ResponsiveContainer>
          <div style={{ }}>
            <div style={{ margin: '2rem 0' }}>
              {renderNOHEfficiency(noh_efficiency)}
            </div>
            <Table
              className={styles.topTable}
              headerClassName={styles.tableHeader}
              columns={columns}
              data={pivotData}
              listView={false}
              highlightAlternateRows
            />
          </div>
        </div>
      </>
  );
};

const renderNOHEfficiency = (value: number | null) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const title = 'NOH Efficiency';
  const adjustedArrowDirection = isMobile ? 'top' : 'left';
  return (
    <div className={styles.nohEfficiency}>
      <Tooltip
        arrowDirection={adjustedArrowDirection}
        disableAutoAdjust={isMobile}
        tooltipTextClassName={isMobile ? styles.mobileTooltip : undefined}
        simpleContent={{
          title,
          description: `NOH Efficiency refers to the percentage of running power shut off non operating hour.
           100% NOH Efficiency would mean all power is shut off during non operating hour.`,
        }}
        clickableKey={title.replace(/\s/g, '-')}
      >
        <div className={styles.title}>{'NOH Efficiency'}</div>
      </Tooltip>
      <div className={styles.value}>{value !== null ? `${formatNumber(value * 100, 1)}%` : '-'}</div>
    </div>
  );
};

const renderLegendText = (value: string, entry: any) => {
  return <span className={styles.legendText}>{value}</span>;
};

export default NonOperatingHourEfficiencyTile;
