import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../reducers";
import { useCurrentOrganizationId } from "./users";
import * as R from 'ramda';
import { useEffect } from "react";
import { 
  getMonthlyNOHReport,
  getOrganizationCO2Report,
  getOrganizationEnergyPdf,
  getOrganizationEnergySummary,
  getOrgNOHReport,
  startMonthlyNOHReport,
  startOrganizationCO2Report,
  startOrganizationEnergyPdf,
  startOrganizationEnergySummary,
  startOrgNOHReport
} from "../actions/analytics";
import { format, subMonths } from "date-fns";

export const useOrganizationCO2Report = (year: number, month: string) => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();

  const { co2Report, reportKey, isLoading, reportVersion, reportFetchTime } =
    useSelector(({ analytics }: ApplicationState) => {
      const co2Report = R.pathOr(
        undefined,
        [orgId, 'data'],
        analytics.organizationCO2Report
      );
      const isLoading = R.pathOr(
        undefined,
        [orgId, 'isLoading'],
        analytics.organizationCO2Report
      );
      const reportKey = R.pathOr(
        undefined,
        [orgId, 'reportKey'],
        analytics.organizationCO2Report
      );
      const reportVersion = R.pathOr(
        0,
        [orgId, 'reportVersion'],
        analytics.organizationCO2Report
      );
      const reportFetchTime = R.pathOr(
        undefined,
        [orgId, 'reportFetchTime'],
        analytics.organizationCO2Report
      );
      return {
        co2Report,
        reportKey,
        isLoading,
        reportVersion,
        reportFetchTime,
      };
    });

  useEffect(() => {
    if (orgId !== undefined) {
      if (reportKey === undefined && !isLoading && !reportVersion) {
        dispatch(startOrganizationCO2Report(orgId, `${year}-${month}-01`));
      }
    }
  }, [orgId, isLoading, co2Report]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrganizationCO2Report(orgId, reportKey));
      }, 2000);
    }
  }, [orgId, reportKey, co2Report, reportVersion, reportFetchTime]);

  return {
    data: co2Report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
  };
};

export const useOrganizationEnergySummary = (year: number, month: string) => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();

  const { report, reportKey, isLoading, reportVersion, reportFetchTime } =
    useSelector(({ analytics }: ApplicationState) => {
      const report = R.pathOr(
        undefined,
        [orgId, 'data'],
        analytics.organizationEnergySummaryReport
      );
      const isLoading = R.pathOr(
        undefined,
        [orgId, 'isLoading'],
        analytics.organizationEnergySummaryReport
      );
      const reportKey = R.pathOr(
        undefined,
        [orgId, 'reportKey'],
        analytics.organizationEnergySummaryReport
      );
      const reportVersion = R.pathOr(
        0,
        [orgId, 'reportVersion'],
        analytics.organizationEnergySummaryReport
      );
      const reportFetchTime = R.pathOr(
        undefined,
        [orgId, 'reportFetchTime'],
        analytics.organizationEnergySummaryReport
      );
      return {
        report,
        reportKey,
        isLoading,
        reportVersion,
        reportFetchTime,
      };
    });

  useEffect(() => {
    if (orgId !== undefined) {
      if (reportKey === undefined && !isLoading && !reportVersion) {
        dispatch(startOrganizationEnergySummary(orgId, `${year}-${month}-01`));
      }
    }
  }, [orgId, isLoading, report]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrganizationEnergySummary(orgId, reportKey));
      }, 2000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
  };
};

export const useOrganizationEnergyPdf = (year: number, month: string) => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();

  const {
    report,
    reportKey,
    isLoading,
    reportVersion,
    reportFetchTime,
    targetLevel,
  } = useSelector(({ analytics }: ApplicationState) => {
    const report = R.pathOr(
      undefined,
      [orgId, 'data'],
      analytics.organizationEnergyPdf
    );
    const isLoading = R.pathOr(
      undefined,
      [orgId, 'isLoading'],
      analytics.organizationEnergyPdf
    );
    const reportKey = R.pathOr(
      undefined,
      [orgId, 'reportKey'],
      analytics.organizationEnergyPdf
    );
    const reportVersion = R.pathOr(
      0,
      [orgId, 'reportVersion'],
      analytics.organizationEnergyPdf
    );
    const reportFetchTime = R.pathOr(
      undefined,
      [orgId, 'reportFetchTime'],
      analytics.organizationEnergyPdf
    );
    const targetLevel = R.pathOr(
      undefined,
      [orgId, 'targetLevel'],
      analytics.organizationEnergyPdf
    );
    return {
      report,
      reportKey,
      isLoading,
      reportVersion,
      reportFetchTime,
      targetLevel,
    };
  });

  useEffect(() => {
    if (orgId !== undefined) {
      if (reportKey === undefined && !isLoading && !reportVersion) {
        dispatch(startOrganizationEnergyPdf(orgId, `${year}-${month}-01`, 500));
      }
    }
  }, [orgId, year, isLoading, report]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrganizationEnergyPdf(orgId, reportKey));
      }, 2000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
    targetLevel,
  };
};

export const useOrgNOHReport = (fromDate, toDate) => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();

  const {
    report,
    reportKey,
    isLoading,
    reportVersion,
    reportFetchTime,
  } = useSelector(({ analytics }: ApplicationState) => {
    const report = R.pathOr(
      undefined,
      [orgId, 'data'],
      analytics.organizationNonOperatingHour
    );
    const isLoading = R.pathOr(
      undefined,
      [orgId, 'isLoading'],
      analytics.organizationNonOperatingHour
    );
    const reportKey = R.pathOr(
      undefined,
      [orgId, 'reportKey'],
      analytics.organizationNonOperatingHour
    );
    const reportVersion = R.pathOr(
      0,
      [orgId, 'reportVersion'],
      analytics.organizationNonOperatingHour
    );
    const reportFetchTime = R.pathOr(
      undefined,
      [orgId, 'reportFetchTime'],
      analytics.organizationNonOperatingHour
    );
    return {
      report,
      reportKey,
      isLoading,
      reportVersion,
      reportFetchTime,
    };
  });

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0
    ) {
      dispatch(startOrgNOHReport(orgId, fromDate, toDate ));
    }
  }, [orgId, fromDate, toDate, isLoading, report]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrgNOHReport(orgId, reportKey));
      }, 5000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
  };
};

export const useMonthlyNOHReport = (fromDate, toDate) => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrganizationId();

  const {
    report,
    reportKey,
    isLoading,
    reportVersion,
    reportFetchTime,
  } = useSelector(({ analytics }: ApplicationState) => {
    const report = R.pathOr(
      undefined,
      [orgId, 'data'],
      analytics.monthlyNonOperatingHour
    );
    const isLoading = R.pathOr(
      undefined,
      [orgId, 'isLoading'],
      analytics.monthlyNonOperatingHour
    );
    const reportKey = R.pathOr(
      undefined,
      [orgId, 'reportKey'],
      analytics.monthlyNonOperatingHour
    );
    const reportVersion = R.pathOr(
      0,
      [orgId, 'reportVersion'],
      analytics.monthlyNonOperatingHour
    );
    const reportFetchTime = R.pathOr(
      undefined,
      [orgId, 'reportFetchTime'],
      analytics.monthlyNonOperatingHour
    );
    return {
      report,
      reportKey,
      isLoading,
      reportVersion,
      reportFetchTime,
    };
  });

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0
    ) {
      dispatch(startMonthlyNOHReport(orgId, fromDate, toDate));
    }
  }, [orgId, fromDate, toDate, isLoading, report]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getMonthlyNOHReport(orgId, reportKey));
      }, 5000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
  };
};