import { addMonths, addYears, format, subDays } from 'date-fns';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMonthlyNOHReport, useOrganizationCO2Report, useOrganizationEnergyPdf, useOrganizationEnergySummary, useOrgNOHReport } from '../../../hooks/useAnalytics';
import { ApplicationState } from '../../../reducers';
import EnergyTrackerReportPage from '../EnergyTrackerReportPage/EnergyTrackerReportPage';

type Props = {
  organizationId: number;
  date: string;
  target: number;
};

const KWH_PER_M2_TARGET = 500;

const EnergyTrackerReportPageContainer: React.FC<Props> = ({
  organizationId,
  date,
  target,
}) => {
  const reportFromDate = new Date(date);
  const reportToDate = subDays(addMonths(reportFromDate, 1), 1);
  const reportYear = reportFromDate.getFullYear();
  const reportMonth = format(reportFromDate, 'MM');
  const orgCO2ReportData = useOrganizationCO2Report(reportYear, reportMonth);
  const orgEnergySummaryData = useOrganizationEnergySummary(reportYear, reportMonth);
  const orgEnergyPDFData = useOrganizationEnergyPdf(reportYear, reportMonth);
  const monthlyNOHData = useMonthlyNOHReport(reportFromDate, reportToDate);
  const orgNOHData = useOrgNOHReport(reportFromDate, reportToDate)

  const orgTitle = useSelector(({ organizations }: ApplicationState) => {
    return organizations?.currentOrganization?.title
  });

  if (
    !orgCO2ReportData.isReportReady ||
    !orgEnergySummaryData.isReportReady ||
    !orgEnergyPDFData.isReportReady || 
    !orgNOHData.isReportReady ||
    !monthlyNOHData.isReportReady
  ) return null;

  return <EnergyTrackerReportPage
    eui={orgEnergyPDFData} 
    co2={orgCO2ReportData} 
    energy={orgEnergySummaryData}
    orgNOH={orgNOHData}
    monthlyNOH={monthlyNOHData}
    date={reportFromDate}
    title={orgTitle}
  />;
};

export default EnergyTrackerReportPageContainer;
